<template>
  <v-container fluid class="pa-0 ma-0">
    <v-layout align-center justify-center v-if="loading" pt-5>
      <div class="text-xs-center">
        <a-icon type="sync" spin :style="{ fontSize: '60px', color: '#08c' }" />
      </div>
    </v-layout>

    <template v-else>
      <!-- NOTE: image fetching sample 2024-07-24 -->
      <!-- <v-btn @click="getImage()">get image</v-btn> -->
      <v-toolbar style="background-color: #f6f5f5" flat>
        <v-tooltip bottom color="black" nudge-top="7">
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              fab
              x-small
              rounded
              v-on="on"
              class="mr-2"
              color="#1565c0"
              @click="CloseTab()"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <!-- Updated by hazel - 20210917 -->
        <!-- <v-toolbar-title>{{ shiyoData.id }} {{ shiyoData.roomPart.toString() }} {{ shiyoData.specifications }}</v-toolbar-title> -->
        <v-toolbar-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ computedTitlePage }}
              </span>
            </template>
            <!-- <span>{{ shiyoData.id }} {{ shiyoData.roomPart.toString() }} {{ computedSpecification }}</span> -->
            <span>{{ shiyoData.shiyoNumber }} </span>
          </v-tooltip>
        </v-toolbar-title>

        <v-btn
          rounded
          @click="openPrintDialog()"
          color="success"
          class="ml-3"
          small
          width="105px"
        >
          <v-icon color="#455A64">mdi-printer</v-icon>Print
        </v-btn>

        <v-btn
          rounded
          small
          color="success"
          width="105px"
          @click="
            isFavorite = !isFavorite;
            addFavorite();
          "
          class="ml-3"
        >
          <v-icon :color="isFavorite ? 'yellow' : 'white'">{{ isFav }}</v-icon>
          <span>Favorite </span>
        </v-btn>

        <v-btn
          rounded
          small
          color="success"
          @click="goToFeedback()"
          class="ml-1"
        >
          <!-- 2022-01-17 tanskie-->
          <v-icon>mdi-comment-quote</v-icon><span>Feedback </span>
        </v-btn>

        <v-spacer></v-spacer>

        <v-tooltip bottom color="black" nudge-top="7">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              color="primary"
              class="mr-2"
              @click="viewHistory($route.params.id)"
              ><v-icon>mdi-history</v-icon></v-btn
            >
          </template>
          <span>View History</span>
        </v-tooltip>
      </v-toolbar>

      <!-- {{shiyoData}} -->

      <v-card class="ma-1 " flat id="print">
        <a-row type="flex" justify="start" v-if="hideProperties">
          <a-col>
            <v-btn class="disabled" rounded text style="width: 40%">
              <span class="ml-5"> メール アドレス: </span>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  class="ml-4"
                  v-on="on"
                  @click="Copy()"
                  v-clipboard:copy="thingToCopy"
                  >sk-shiyou@ichijo.co.jp
                </a>
              </template>
              <span>Copy mail address to clipboard</span>
            </v-tooltip>
          </a-col>

          <a-col class="mt-2">
            <span class="ml-4">仕様マニュアル更新日：</span>
            <span>{{ getUpdatedDate(shiyoData) }}</span>
          </a-col>
          <a-col :span="24"><hr /></a-col>
        </a-row>

        <a-row v-if="shiyoData.isWithExcelAttachment">
          <!-- 2022-04-08 -->
          <v-alert
            dismissible
            prominent
            type="error"
            color="orange"
            dense
            border="left"
            class="mx-1 mt-1"
            elevation="2"
          >
            <h3 class="white--text" style="font-weight:bold">
              Note: If the EXCEL FILE doesn't download
              <v-btn @click="openNoteImageDialog()" small color="info">
                Click Me</v-btn
              >
            </h3>
          </v-alert>
        </a-row>

        <a-row type="flex" justify="start" v-if="hideProperties">
          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span>建物構法: {{ shiyoData.framework }}</span>
            </v-btn>
          </a-col>

          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <!-- <span>資料名: {{ computedSpecification }}</span> -->
              <span>資料区分: {{ computedSpecification }}</span>
              <!-- 2022-02-14 -->
            </v-btn>
          </a-col>

          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <!-- <span>資料名: {{ shiyoData.specifications }} </span> -->
              <span>部屋・部位: {{ shiyoData.roomPart.toString() }}</span>
              <!-- 2022-02-14 -->
            </v-btn>
          </a-col>

          <!-- <a-col :span="6">
                    <v-btn rounded text class="disabled">
                    <span>しよ番号: {{shiyoData.shiyoNumber}}</span>
                    </v-btn>
                </a-col> -->
          <a-col :span="24"><hr /></a-col>
        </a-row>
        <!-- *************************************************************************** -->
        <a-row type="flex" justify="start" v-if="hideProperties">
          <v-col cols="2">
            <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.YumeNoIe"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;夢の家
            </v-btn> -->
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Famille">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;ファミーユ（３階建）
            </v-btn>

            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Icube">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-cube
            </v-btn>

            <!-- <v-btn rounded text class="disabled">
             <v-icon v-if="shiyoData.F2TD"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF2-TD
            </v-btn> -->
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Menshin">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;免震
            </v-btn>
          </v-col>

          <v-col cols="2">
            <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Ippan"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;一般
            </v-btn> -->
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Taishin">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;耐震
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Ismart">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smart
            </v-btn>
            <br />
            <!-- 2022-08-10-->
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.JapaneseStyle"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;和風百年
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.G">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;
              <!-- グランド セゾン 2022-03-07 -->
              グラン・セゾン
            </v-btn>
            <br />

            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Ismile">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smile
            </v-btn>

            <br />
            <!-- 2022-08-10 -->
            <!-- <v-btn rounded text class="disabled">
             <v-icon v-if="shiyoData.Saison"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンアシュレ
            </v-btn> -->

            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.HUGme">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;HUGme
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.F">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.Gsmart">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp; グラン・スマート
              <!-- GSMART 2022-03-07-->
            </v-btn>

            <br />
            <!-- 2022-08-10 -->
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.HUGmefam"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;HUGme fam
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.A">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンA
            </v-btn>
            <br />

            <br />
            <!-- 2023-02-22 -->
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.B">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close</v-icon>&nbsp;ブリアール
            </v-btn>
            <br />

            <br />
            <!-- 2023-03-28 -->
          </v-col>
        </a-row>
        <!-- <v-row no-gutters v-if="hideProperties">
                <v-col cols="2.4">
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.G">mdi-check-circle-outline</v-icon>
                    <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;グラン・セゾン
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.Gsmart">mdi-check-circle-outline</v-icon>
                        <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;グラン・スマート
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.Menshin">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;免震
                    </v-btn>
                </v-col>

                <v-col cols="2.4">
                    <v-btn rounded text class="disabled">
                        <v-icon v-if="shiyoData.F">mdi-check-circle-outline</v-icon>
                        <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンF
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled" style="text-transform: lowercase;">
                        <v-icon v-if="shiyoData.Ismart">mdi-check-circle-outline</v-icon>
                        <v-icon icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-smart
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.Taishin">mdi-check-circle-outline</v-icon>
                                <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;耐震
                    </v-btn>
                </v-col>

                <v-col cols="2.4">
                    <v-btn rounded text class="disabled">
                        <v-icon v-if="shiyoData.F2TD">mdi-check-circle-outline</v-icon>
                        <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンF2-TD
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled" style="text-transform: lowercase;">
                        <v-icon v-if="shiyoData.Icube">mdi-check-circle-outline</v-icon>
                        <v-icon icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-cube
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.YumeNoIe">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;夢の家
                    </v-btn>
                </v-col>

                <v-col cols="2.4">
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.A">mdi-check-circle-outline</v-icon>
                    <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンA
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled" style="text-transform: lowercase;">
                        <v-icon v-if="shiyoData.Ismile">mdi-check-circle-outline</v-icon>
                        <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-smile
                    </v-btn>
                    <br />
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.Ippan">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;一般
                    </v-btn>
                </v-col>

                <v-col cols="2.4">
                    <v-btn rounded text class="disabled">
                    <v-icon v-if="shiyoData.B">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;ブリアール
                    </v-btn>
                </v-col>
            </v-row> -->
        <hr />
        <v-layout pt-4 pl-4>
          <!-- <p style="font-size: 20px; padding-top: 0">商品名（項目）: &nbsp;</p> -->
          <!-- v-html="shiyoData.productName.toUpperCase()" -->
          <p style="font-size: 20px; padding-top: 0; font-weight: bold;">
            件名: {{ shiyoData.productName.toUpperCase() }}
          </p>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="hideProperties = !hideProperties">
            <span v-if="!hideProperties"
              >View more details
              <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
            </span>
            <span v-else
              >Hide details <v-icon>mdi-arrow-up-drop-circle-outline</v-icon>
            </span>
          </v-btn>
        </v-layout>
        <hr />

        <v-card
          class="ma-1 pa-1"
          flat
          :height="!hideProperties ? screenSize - 160 : screenSize - 350"
          style="overflow-y: scroll"
        >
          <!-- <div class="fr-view" v-html="shiyoData.htmlContent"></div> -->
          <!-- <froalaView v-html="viewedRule.document.content.toString()"></froalaView> -->
          <!-- {{viewedRule.document.content.toString()}} -->

          <!-- <v-flex
                    xs12
                    v-for="(item, index) in shiyoData.htmlContent"
                    :key="index"
                    >
                    
                <div class="fr-view" v-html="item"></div>
                
              
                </v-flex> -->
          <!-- <v-flex xs12
            ><div class="fr-view" v-html="shiyoData.htmlContent"></div
          ></v-flex> -->

          <!-----REMOVED 2024011------->
          <editor
            v-if="showEditor && loading == false"
            class="px-3"
            :init="tiny_init"
            v-model="shiyoData.htmlContent"
            height="1500"
            id="tinymce_viewing"
          ></editor>
        </v-card>

        <!-- Shiyo PDF Viewer 2022-03-26 -->
        <v-dialog
          v-model="ViewPdfDialog"
          persistent
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-if="ViewPdfDialog">
            <shiyo-pdf-viewer
              :closeShiyoDialog="onCloseShiyoDialog"
              :shiyoDetails="shiyoData"
            />
          </template>
        </v-dialog>

        <!-- Note Image Viewer 2022-04-08-->
        <v-dialog v-model="noteImageDialog" persistent full-screen>
          <v-card>
            <v-card-title>
              Manual
              <v-spacer></v-spacer>
              <v-btn
                @click="noteImageDialog = !noteImageDialog"
                color="warning"
              >
                Close
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-layout align-center justify-center class="pa-1 ma-1">
                <img width="100%" v-if="viewImage" :src="viewImage" />
                <img v-else src="/loading.gif" />
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </template>
  </v-container>
</template>

<script>
//============================================//

//============================================//
import axios from "axios";
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
import * as binconv from "binconv";
import FileSaver from "file-saver";
import converter from "base64-arraybuffer";
import Swal from "sweetalert2";
import moment from "moment";

import Editor from "@tinymce/tinymce-vue";
import tinymce from "tinymce/tinymce";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

import ShiyoPdfViewer from "./ShiyoPdfViewer.vue";

export default {
  title() {
    return `${this.$route.params.id} - ${this.$route.query.productName}`;
  },
  components: {
    editor: Editor,
    "shiyo-pdf-viewer": ShiyoPdfViewer,
  },
  data() {
    return {
      tiny_init: {
        // Tiny Configuration for Viewing
        object_resizing: false,
        inline: true,
        readonly: 1,
        toolbar: false,
        visual: false,
        menubar: false,
        skin: false,
        content_css: false,
        content_style:
          [contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
      },

      editorContent: "",
      printDialog: false,
      titlePage: "",
      historyDialog: false,
      isFavorite: false,
      loading: false,
      shiyoData: {
        docNo: "",
        documentType: "",
        framework: "",
        htmlContent: "",
        id: "",
        productName: "",
        roomPart: "",
        shiyoNumber: "",
        specifications: "",
        textContent: "",
        _id: "",
        _rev: "0",
      },
      shiyoData2: {
        docNo: "",
        documentType: "",
        framework: "",
        htmlContent: "",
        id: "",
        productName: "",
        roomPart: "",
        shiyoNumber: "",
        specifications: "",
        textContent: "",
        _id: "",
        _rev: "0",
      },
      hideProperties: false,
      thingToCopy: `sk-manual@ichijo.co.jp`,
      showEditor: false,

      productNameArr: [],

      ViewPdfDialog: false, // 2022-03-26
      noteImageDialog: false,
      noteImageSrc: "",

      tmpShiyoNumber: "",
    };
  },
  computed: {
    computedSpecification() {
      if (this.shiyoData.specifications) {
        if (this.shiyoData.specifications.match(/[0-9]/g)) {
          return this.shiyoData.specifications.substring(
            0,
            this.shiyoData.specifications.length - 3
          );
        } else {
          return this.shiyoData.specifications;
        }
      } else {
        return "";
      }
    },
    computedTitlePage() {
      let tempText = this.shiyoData.shiyoNumber;

      if (tempText.length >= 25) {
        return tempText.substring(0, 21) + "...";
      } else {
        return tempText;
      }
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    viewImage() {
      return this.noteImageSrc;
    },
  },
  created() {
    ////change browser tab title////
    // const title = this.getTitle(this)

    // if (title) {
    //     document.title = title
    // }
    // console.log('line 493',this.$router)
    this.hideProperties = true; // 2022-03-07 Auto Show View More Details

    let url = `${this.api}shiyo/shiyo_manual_productName/SHIYO`;
    axios.get(url).then((res) => {
      this.productNameArr = res.data;
    });

    setTimeout(() => {
      this.checkFavorite(); // 2022-06-10
    }, 800);
  },
  methods: {
    // NOTE: image fetching sample 2024-07-04
    // async getImage(){
    //   console.log(await this.ImageSrc('ハングアウト①-20240228150271.JPG'))
    // },
    //NOTE: highlight searched text 2024-05-16 start
    async highlightSearchedText(){
      // console.log('nag highlight');
      if (this.$route.query.search != "" || this.$route.query.search != undefined) {
        var editor = document.querySelector('.mce-content-body')
        var content = editor.innerHTML
        var searchText = this.$route.query.search ? this.$route.query.search.trim() : "";
        if(searchText.match(/\s/g)){
          let searchTextArr = searchText.split(/\s/g);
          for(let searchIndex of searchTextArr){
            if (content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"").includes(searchIndex)) {
              let textList = content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"@htmlTag").split(/@htmlTag/)
              let tagList = content.match(/(<([^>]+)>)|&nbsp;|&nbsp/gi)
              let textWithMark = textList.map((rec,i)=>{
                let regex = new RegExp(`(${searchIndex})`,"ig")
                if(textList.length-1 != i){
                  return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`) + tagList[i] 
                }
                else{
                  return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`)
                }
              })
              content = textWithMark.join('')
              editor.innerHTML = content
            }
          }
          editor.innerHTML = content
        }
        else{
          if (content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"").includes(searchText)) {
            let textList = content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"@htmlTag").split(/@htmlTag/)
            let tagList = content.match(/(<([^>]+)>)|&nbsp;|&nbsp/gi)
            let textWithMark = textList.map((rec,i)=>{
              let regex = new RegExp(`(${searchText})`,"ig")
              if(textList.length-1 != i){
                return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`) + tagList[i] 
              }
              else{
                return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`)
              }
            })
            content = textWithMark.join('')
            editor.innerHTML = content
          }
        }
      }
    },
    //NOTE: highlight searched text 2024-05-16 end
    openNoteImageDialog() {
      // 2022-04-08
      let fileName = "noteImage.png";
      AWS.config.update({
        // httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: {
          accessKeyId: this.accessKeyId,
          secretAccessKey: this.secretAccessKey,
          region: "us-east-2",
          apiVersions: {
            s3: "2012-10-17",
          },
        },
      });

      //configuration for PRIVATE S3 Bucket
      var s3 = new AWS.S3({
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
      });
      var options = {
        Bucket: "rulebook.files",
        Key: `Document_images/${fileName}`,
      };

      ////FUNCTION USED IN AWS S3
      s3.getObject(options, (err, data) => {
        if (err) console.log(err, err.stack);
        // an error occurred
        else {
          var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });
          this.noteImageSrc = URL.createObjectURL(blob);
          this.noteImageDialog = true;
        }
      });
    },
    goToFeedback() {
      // 2022-01-17 tanskie
      const url = `${this.api}shiyo/get_shiyo/${this.shiyoData._id}`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            // console.log("line466", res.data);
            this.CHANGE_SHIYO_FEEDBACK(res.data);
            if (this.$router.currentRoute.name != "feedback") {
              window.open(
                `${this.linkURL}/feedback?id=${this.shiyoData._id}`,
                "_blank"
              );
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getUpdatedDate(data) {
      return moment(data.updatedDate).format("YYYY-MM-DD");
    },
    Copy() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Copy mail address to clipboard",
        showConfirmButton: false,
        timer: 800,
      });
    },
    ////change browser tab title////
    getTitle(vm) {
      const { title } = vm.$options;

      if (title) {
        return typeof title === "function" ? title.call(vm) : title;
      }
    },
    viewHistory() {
      // let url = `http://hrdapps59:2429/document/get/final/${id}`;
      // axios.get(url).then((res) => {
      //     console.log('DATA FROM RULEBOOK LOCAL DATABASE')
      //     console.log(res.data)
      //     this.historyData = res.data.data
      // });
      // this.historyDialog = true;
    },
    addFavorite() {
      // console.log( 'line673', this.shiyoData )
      // console.log( 'line674', this.isFavorite )
      let toInsert = {
        id: this.shiyoData.id,
        productName: this.shiyoData.productName,
        userId: this.userInfo.id,
        isFavorite: this.isFavorite,
      };

      let url = `${this.api}shiyo/addFavorite`;
      let url2 = `${this.api}shiyo/addFavoriteCount`;
      axios
        .post(url2, toInsert)
        .then(() => {
          axios
            .post(url, toInsert)
            .then((res) => {
              if (res.data == "Add Favorite") {
                Swal.fire({
                  icon: "success",
                  title: `${this.shiyoData.shiyoNumber} added to Favorites`,
                  showConfirmButton: false,
                  timer: 1200,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: `${this.shiyoData.shiyoNumber} deleted to Favorites`,
                  showConfirmButton: false,
                  timer: 1200,
                });
              }
            })
            .catch((err2) => {
              console.log(err2.message);
            });
        })
        .catch((err1) => {
          console.log(err1.message);
        });
      // axios.defaults.headers.common["x-api-key"] =
      //     "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      // let toInsert = {
      //     id: this.$route.params.id.substring(0, 9),
      //     userId: this.userInfo.id,
      //     isFavorite: this.isFavorite,
      // };
      // // console.log(toInsert)
      // let url = `${this.api}addFavorite`;
      // let url2 = `${this.api}addFavoriteCount`;
      // axios.post(url2, toInsert).then(() => {
      //     axios.post(url, toInsert).then((res) => {
      //     // console.log(res.data)
      //     if (res.data == "Add Favorite") {
      //         this.SET_FAVORITE(this.isFavCnt + 1);
      //         Swal.fire({
      //         icon: "success",
      //         title: `${this.$route.params.id.substring(
      //             0,
      //             9
      //         )} added to Favorites`,
      //         showConfirmButton: false,
      //         timer: 1200,
      //         });
      //     } else {
      //         this.SET_FAVORITE(this.isFavCnt - 1);
      //         Swal.fire({
      //         icon: "error",
      //         title: `${this.$route.params.id.substring(
      //             0,
      //             9
      //         )} deleted to Favorites`,
      //         showConfirmButton: false,
      //         timer: 1200,
      //         });
      //     }
      //     });
      // });
    },
    //ongoing PRINT
    openPrintDialog() {
      // window.open(
      //   `${this.linkURL}/tinyEditor/${this.shiyoData.id}?productName=${this.shiyoData.productName}`,
      //   "_blank"
      // );
      window.open(
        `/tinyEditor/${this.shiyoData.id}?productName=${this.shiyoData.productName}`,
        "_blank"
      );
    },
    CloseTab() {
      window.close();
    },
    ImageSrc(imgName) {
      return new Promise((resolve) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID: this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
          params: {
            bucket: "rulebook.files",
          },
        });

        var options = {
          Bucket: "rulebook.files",
          Key: `Shiyo/${imgName}`,
        };
        s3.getObject(options, function(err, data) {
          if (err) {
            console.log(err, err.stack);
          } else {
            const base64 = converter.encode(data.Body);
            let content = `data:${data.ContentType};base64,`;
            let finalcontent = `${content}${base64}`;
            this.imagesrc = finalcontent;
            resolve(this.imagesrc);
          }
        });
      });
    },
    async ReplaceImageFromS3(url) {
      //ongoing
      let findXLS = new RegExp(".xls", "g");
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      await axios.get(url).then(async (res) => {
        // console.log("line 650", res.data);

        // Change Tab Name ( 2022-03-05 )
        document.title = `${res.data.shiyoNumber}-${res.data.productName}`;

        let data = res.data.htmlContent
        const strToBase64 = (str) => {
          let indices = [];
          let imgName = [];

          let findStr = new RegExp(
            "http://hrdapps48:5010/uploads/image/froala/test/",
            //NOTE: for change imagelink 2024-03-26
            // "http://10.168.64.31:5010/uploads/image/froala/test/|http://hrdapps48:5010/uploads/image/froala/test/|https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/|https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/",
            "g"
          );

          let result = "";
          while ((result = findStr.exec(str))) {
            indices.push(result.index);
            // NOTE: for change imagelink 2024-03-26
            // indices.push([result.index,result[0]]);
          }
          //NOTE: for change imagelink 2024-03-26
          // let findExt = new RegExp(".png|.pjpeg|.pjp|.jpg|.jpeg|.jfif|.bmp|.ico|.cur|.tiff|.tif|.webp|.svg|.gif|.avif|.apng", "ig")
          if (indices.length > 0) {
            imgName = indices.map((index) => {
              return str.substring(index + 48, index + 48 + 44);
            });
            //NOTE: for change imagelink 2024-03-26
            // imgName = indices.map((item) => {
            //     let end = findExt.exec(str,item[0]).index + 4
            //     // return str.substring(index + 48, index + 48 + 44);
            //     return str.substring(item[0] + item[1].length, end);
            // });
          }
          return imgName;
        };
        let s3Keys = data.map((rec) => {
          return strToBase64(rec);
        });

        // console.log('all images',s3Keys)
        s3Keys = [].concat.apply([], s3Keys);
        let promises = [];
        if (s3Keys.length > 0) {
          //   console.log("nag if sa una");
          for (let i = 0; i < s3Keys.length; i++) {
            promises.push(this.ImageSrc(s3Keys[i]));
          }

          let newContent = res.data.htmlContent.join("-----")
          Promise.all(promises).then((datas) => {
            for (let i = 0; i < datas.length; i++) {
              let imageName = s3Keys[i].replace('(','[(]').replace(')','[)]')
              let toReplace = new RegExp(
                `http://hrdapps48:5010/uploads/image/froala/test/${imageName}`,
                //NOTE: for change imagelink 2024-03-26
                // `http://10.168.64.31:5010/uploads/image/froala/test/${imageName}|http://hrdapps48:5010/uploads/image/froala/test/${imageName}|https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/${imageName}|https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/${imageName}`,
                "g"
              );
              newContent = newContent.replace(toReplace, datas[i]);
            }
            // console.log("line 676", res.data.htmlContent);
            res.data.htmlContent = newContent.split("-----");
            if (res.data.htmlContent.toString().match(findXLS)) {
              //   console.log("nag if sa if");
              this.shiyoData2 = res.data;
              this.replaceLink();
            } else {
              //   console.log("nag else sa if");
              this.shiyoData = res.data;
              let newHtml = "";
              this.shiyoData.htmlContent.forEach((rec) => {
                newHtml += rec;
              });

              // console.log("line839", this.shiyoData.htmlContent);

              this.shiyoData.htmlContent = newHtml.toString();
              this.showEditor = true;
              // console.log("line 698", typeof this.shiyoData.htmlContent);
              setTimeout(() => {
                tinymce.activeEditor
                  .getBody()
                  .setAttribute("contenteditable", false);
                this.loading = false;

                let length = document.getElementsByTagName("table").length;
                let table = document.getElementsByTagName("table");
                // console.log("line 708", table);
                for (let i = 0; i <= length - 1; i++) {
                  let width = document.getElementsByTagName("table")[i].style
                    .width;
                  if (width.includes("%")) {
                    // console.log(i, table[i].getAttribute("style"));
                    let pixelWidth = (parseFloat(width) / 100) * 1200;
                    let oldAttribute = table[i]
                      .getAttribute("style")
                      .toString();
                    let widthIndex = oldAttribute.indexOf("width");
                    let firstConcat = oldAttribute.substring(0, widthIndex);
                    let scndConcat = oldAttribute.substring(
                      widthIndex + 17,
                      oldAttribute.length
                    );
                    table[i].setAttribute(
                      "style",
                      `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
                    );
                  }
                }

                // 2022-03-03 ( For Shiyo Link and rulebook link  ) // eto
                let links = document.getElementsByTagName("a");
                // console.log("tan3", links);
                let linksLen = links.length - 1;
                // console.log("index 2", links[2]);
                // console.log("length", linksLen);

                for (let i = 0; i <= linksLen; i++) {
                  let linkText = [];
                  if (links[i].hasAttribute("href")) {
                    // tanskie2
                    linkText = links[i].getAttribute("href");
                  }

                  let linkSplit = "";
                  let linkSplit1 = ""; // 2022-03-08

                  let linkSplit2 = ""; // 2022-04-13
                  let FileName = ""; // 2022-04-13

                  if (linkText.length) {
                    linkSplit = linkText.toString().split("/")[0];
                    linkSplit1 = linkText.toString().split(":")[1]; // 2022-03-08

                    // ( 2022-04-13)
                    linkSplit2 = linkText.toString().split(".")[
                      linkText.split(".").length - 1
                    ];
                    console.log("line1197 Extension", linkSplit2);

                    FileName = linkText.split("/")[
                      linkText.split("/").length - 1
                    ];
                    console.log("line1201 Filename", FileName);
                  }

                  let newLink = "";
                  if (linkText.includes("shiyo_document")) {
                    let id = linkText.toString().split("/")[1];
                    newLink = linkText.replace(
                      /shiyo_document/g,
                      "shiyoDocument"
                    );
                    // console.log('line 786', newLink)
                    let newProduct = "";

                    let productObj = this.productNameArr.filter((rec) => {
                      return rec.id == id;
                    });
                    // console.log(i, productObj[0].productName)
                    if (
                      productObj.length &&
                      productObj[0].productName !== undefined
                    ) {
                      newProduct = productObj[0].productName;
                    }
                    links[i].setAttribute(
                      "href",
                      `${this.linkURL}/${newLink}?productName=${newProduct}`
                    );

                    links[i].setAttribute("target", "_blank"); // 2022-03-04
                  }

                  if (linkSplit == "document") {
                    // console.log(i, 'value')
                    links[i].setAttribute(
                      "href",
                      `${this.linkURL}/${linkText}`
                    );

                    links[i].setAttribute("target", "_blank"); // 2022-03-04
                  }

                  // 2022-03-08 For Shiyo Manual PDF Only
                  if (linkSplit1 == "//hrdapps48") {
                    links[i].setAttribute("href", `${linkText}`);
                    links[i].setAttribute("target", "_blank"); // 2022-03-04
                  }

                  // EXCEL DOWNLOAD LINK ( 2022-04-13 )
                  //     if(  linkSplit2 == 'xlsx' || linkSplit2 == 'xls' || linkSplit2 == 'docx' ) {

                  //     let s3Url = 'https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files'
                  //     console.log('line1301', this.shiyoData.shiyoNumber)
                  //     let reference = `${s3Url}/${this.shiyoData.shiyoNumber}-${FileName}`
                  //     setTimeout(() => {
                  //         // Remove File Name in Directory
                  //         let linkDeleteEnd = reference.split('/')
                  //         linkDeleteEnd.pop()
                  //         let linkDeleted = linkDeleteEnd.join('/')
                  //         let dir = linkDeleted

                  //         // Remove Duplicate Shiyo Number
                  //         let linkSplit = reference.split('/')[reference.split('/').length -1 ];
                  //         let titleName = linkSplit.split('-')
                  //         titleName.shift();
                  //         let fileName = titleName.join('-')

                  //         links[i].setAttribute('href', `${dir}/${fileName}`);
                  //         links[i].setAttribute('target', '_blank')
                  //     }, 1200);
                  //    }

                  continue;
                }
              }, 500);
            }
            // this.loading = false;
          });
        } else {
          // console.log("nag else sa una");
          this.shiyoData2 = res.data;
          if (res.data.htmlContent.toString().match(findXLS) != null) {
            this.shiyoData2 = res.data;
            // console.log("nag if sa pangalawa");
            this.replaceLink();
          } else {
            // console.log("nag else sa pangalawa");

            this.shiyoData = res.data;
            // console.log('line962', this.shiyoData)

            let newHtml = "";
            this.shiyoData.htmlContent.forEach((rec) => {
              newHtml += rec;
            });

            this.shiyoData.htmlContent = newHtml.toString();
            this.showEditor = true;
            // console.log("line 698", typeof this.shiyoData.htmlContent);
            setTimeout(() => {
              tinymce.activeEditor
                .getBody()
                .setAttribute("contenteditable", false);
              this.loading = false;
              let length = document.getElementsByTagName("table").length;
              let table = document.getElementsByTagName("table");
              for (let i = 0; i <= length - 1; i++) {
                let width = document.getElementsByTagName("table")[i].style
                  .width;
                if (width.includes("%")) {
                  // console.log(i, table[i].getAttribute("style"));
                  let pixelWidth = (parseFloat(width) / 100) * 1200;
                  let oldAttribute = table[i].getAttribute("style").toString();
                  let widthIndex = oldAttribute.indexOf("width");
                  let firstConcat = oldAttribute.substring(0, widthIndex);
                  let scndConcat = oldAttribute.substring(
                    widthIndex + 17,
                    oldAttribute.length
                  );
                  table[i].setAttribute(
                    "style",
                    `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
                  );
                }
              }

              // 2022-03-03 ( For Shiyo Link and rulebook link )
              let links = document.getElementsByTagName("a");
              // console.log("tan1", links);
              let linksLen = links.length - 1;
              // console.log("index 2", links[2]);
              // console.log("length", linksLen);

              for (let i = 0; i <= linksLen; i++) {
                let linkText = [];
                if (links[i].hasAttribute("href")) {
                  // tanskie2
                  linkText = links[i].getAttribute("href");
                }

                let linkSplit = "";
                let linkSplit1 = ""; // 2022-03-08

                let linkSplit2 = ""; // 2022-04-13
                let FileName = ""; // 2022-04-13

                if (linkText.length) {
                  linkSplit = linkText.toString().split("/")[0];
                  linkSplit1 = linkText.toString().split(":")[1]; // 2022-03-08

                  // ( 2022-04-13)
                  linkSplit2 = linkText.toString().split(".")[
                    linkText.split(".").length - 1
                  ];
                  console.log("line1197 Extension", linkSplit2);

                  FileName = linkText.split("/")[
                    linkText.split("/").length - 1
                  ];
                  console.log("line1201 Filename", FileName);
                }

                let newLink = "";
                if (linkText.includes("shiyo_document")) {
                  let id = linkText.toString().split("/")[1];
                  newLink = linkText.replace(
                    /shiyo_document/g,
                    "shiyoDocument"
                  );
                  // console.log('line 786', newLink)
                  let newProduct = "";

                  let productObj = this.productNameArr.filter((rec) => {
                    return rec.id == id;
                  });
                  // console.log(i, productObj[0].productName)
                  if (
                    productObj.length &&
                    productObj[0].productName !== undefined
                  ) {
                    newProduct = productObj[0].productName;
                  }
                  links[i].setAttribute(
                    "href",
                    `${this.linkURL}/${newLink}?productName=${newProduct}`
                  );

                  links[i].setAttribute("target", "_blank"); // 2022-03-04
                }

                if (linkSplit == "document") {
                  // console.log(i, 'value')
                  links[i].setAttribute("href", `${this.linkURL}/${linkText}`);

                  links[i].setAttribute("target", "_blank"); // 2022-03-04
                }

                // For Shiyo Manual PDF Only
                if (linkSplit1 == "//hrdapps48") {
                  links[i].setAttribute("href", `${linkText}`);
                  links[i].setAttribute("target", "_blank"); // 2022-03-04
                }

                // EXCEL DOWNLOAD LINK ( 2022-04-13 )
                // if(  linkSplit2 == 'xlsx' || linkSplit2 == 'xls' || linkSplit2 == 'docx' ) {

                //     let s3Url = 'https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files'
                //     console.log('line1301', this.shiyoData.shiyoNumber)
                //     let reference = `${s3Url}/${this.shiyoData.shiyoNumber}-${FileName}`
                //     setTimeout(() => {
                //         // Remove File Name in Directory
                //         let linkDeleteEnd = reference.split('/')
                //         linkDeleteEnd.pop()
                //         let linkDeleted = linkDeleteEnd.join('/')
                //         let dir = linkDeleted

                //         // Remove Duplicate Shiyo Number
                //         let linkSplit = reference.split('/')[reference.split('/').length -1 ];
                //         let titleName = linkSplit.split('-')
                //         titleName.shift();
                //         let fileName = titleName.join('-')

                //         links[i].setAttribute('href', `${dir}/${fileName}`);
                //         links[i].setAttribute('target', '_blank')
                //     }, 1200);
                // }

                continue;
              }
            }, 500);
          }
          // this.loading = false
        }

        // setTimeout(() => {
        //                 tinymce.activeEditor.getBody().setAttribute('contenteditable', false);
        //                 this.loading = false

        //                 let length = document.getElementsByTagName('table').length
        //                 let table = document.getElementsByTagName('table')
        //                 console.log('line 708',table)
        //                 for(let i = 0; i <= length - 1; i++){
        //                     let width = document.getElementsByTagName('table')[i].style.width;
        //                     if(width.includes('%')){
        //                         console.log(i, table[i].getAttribute('style'))
        //                         let pixelWidth  = ((parseFloat(width) / 100) * 1200)
        //                         let oldAttribute = table[i].getAttribute('style').toString()
        //                         let widthIndex = oldAttribute.indexOf('width')
        //                         let firstConcat = oldAttribute.substring(0 , widthIndex)
        //                         let scndConcat = oldAttribute.substring(widthIndex + 17 , oldAttribute.length)
        //                         table[i].setAttribute('style', `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`);
        //                     }

        //                 }
        //             }, 100);
        // let newHtml = ''
        // await this.shiyoData.htmlContent.forEach(rec =>{
        //     newHtml += rec
        // })

        // this.shiyoData.htmlContent = newHtml.toString()
        // this.showEditor = true
        // console.log('line 698', typeof this.shiyoData.htmlContent)
        // setTimeout(() => {
        //     tinymce.activeEditor.getBody().setAttribute('contenteditable', false);
        // }, 500);

        // IF PDF Attachment ( PDF Attachment  )
        if (this.shiyoData.isWithPDFAttachment) {
          // 2022-03-26
          this.ViewPdfDialog = true;
        }
        // IF EXCEL Attachment ( 2022-04-05)
        else if (this.shiyoData.isWithExcelAttachment) {
          // 2022-03-26
          let url ="https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files/";
          let excelFileName = this.shiyoData.fileName;
          window.open(`${url}${excelFileName}`);
        }
      });
      setTimeout(() => {
        this.highlightSearchedText()
      }, 1000);
    },
    onCloseShiyoDialog() {
      // 2022-03-26
      this.ViewPdfDialog = false;
    },
    replaceLink() {
      let findXLS = new RegExp(".xls", "g");
      if (this.shiyoData2.htmlContent.toString().match(findXLS) != null) {
        // for(let j = 0; j < this.shiyoData2.htmlContent.length; j++){
        //     let findLink = new RegExp(
        //         // `href="http://hrdapps48:3001/ftp/rule_book/`, ////edited by hazel - 20210917
        //         `href="http://hrdapps48:3001/ftp/shiyomanual/`,
        //         "g"
        //     )
        //     let indicesLink = [];
        //     let indicesXLS = [];
        //     let indicesXLS2 = [];
        //     let Newfunction = [];
        //     let result = "";
        //     while(
        //         (result = findLink.exec(this.shiyoData2.htmlContent[j]))
        //     ){
        //         indicesLink.push(result.index);
        //     }

        //     while ((result = findLink.exec(this.shiyoData2.htmlContent[j]))) {
        //         if(result[0] == ".xls") {
        //             indicesXLS.push(result.index);
        //         }
        //     }

        //     for(let i=0; i < indicesLink.length; i++){
        //         let contentLink = this.shiyoData2.htmlContent[j]
        //         this.shiyoData2.htmlContent[j] = contentLink.replace(
        //             findLink,
        //             function(){
        //                 return `onclick="FileSrc('`
        //             }
        //         )
        //     }
        //     while((result = findXLS.exec(this.shiyoData2.htmlContent[j]))){
        //         if(result[0] == ".xls"){
        //             indicesXLS2.push(result.index)
        //         }
        //     }
        //     let findFunction = new RegExp("onclick", "g")
        //     while((result = findFunction.exec(this.shiyoData2.htmlContent[j]))){
        //         Newfunction.push(result.index)
        //     }

        //     let attribs = new RegExp(
        //         `rel="noopener noreferrer", target="_blank"`,
        //         "g"
        //     )
        //     this.shiyoData2.htmlContent[j] = this.shiyoData2.htmlContent[j].replace(/.xls/g, function (str){
        //         if(str == ".xls"){
        //             return `${str}`
        //         }else{
        //             return str;
        //         }
        //     }).replace(attribs, "");

        //     let tempContent = this.shiyoData2.htmlContent[j]
        //     this.shiyoData2.htmlContent[j] = this.shiyoData2.htmlContent[j].replace(/.pdf/g, function (str, index){
        //         if(tempContent.substring(index, index - 50).match(/https:/)){
        //             return str
        //         }else{
        //             if (str == ".pdf" ) {
        //                 return `${str}`; // 2022-02-11
        //             } else return str;
        //         }
        //     }).replace(attribs, "")
        // }

        this.shiyoData = this.shiyoData2;
      } else {
        this.shiyoData = this.shiyoData2;
      }

      let newHtml = "";
      this.shiyoData.htmlContent.forEach((rec) => {
        newHtml += rec;
      });

      this.shiyoData.htmlContent = newHtml.toString();

      this.showEditor = true;
      // console.log("line 698", typeof this.shiyoData.htmlContent);
      setTimeout(() => {
        tinymce.activeEditor.getBody().setAttribute("contenteditable", false);
        this.loading = false;
        let length = document.getElementsByTagName("table").length;
        let table = document.getElementsByTagName("table");
        // console.log("line 708", table);
        for (let i = 0; i <= length - 1; i++) {
          let width = document.getElementsByTagName("table")[i].style.width;
          if (width.includes("%")) {
            // console.log(i, table[i].getAttribute("style"));
            let pixelWidth = (parseFloat(width) / 100) * 1200;
            let oldAttribute = table[i].getAttribute("style").toString();
            let widthIndex = oldAttribute.indexOf("width");
            let firstConcat = oldAttribute.substring(0, widthIndex);
            let scndConcat = oldAttribute.substring(
              widthIndex + 17,
              oldAttribute.length
            );
            table[i].setAttribute(
              "style",
              `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
            );
          }
        }

        // 2022-03-03 ( For Shiyo Link and rulebook link  ) // eto
        let links = document.getElementsByTagName("a");
        // console.log("tan2", links);
        // //NOTE: highlight searched text 2024-05-14 start
        // if (this.$route.query.search != "") {
        //   var editor = document.querySelector('.mce-content-body')
        //   var content = editor.innerHTML
        //   var searchText = this.$route.query.search.trim();
        //   if(searchText.match(/\s/g)){
        //     let searchTextArr = searchText.split(/\s/g);
        //     for(let searchIndex of searchTextArr){
        //       if (content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"").includes(searchIndex)) {
        //         let textList = content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"@htmlTag").split(/@htmlTag/)
        //         let tagList = content.match(/(<([^>]+)>)|&nbsp;|&nbsp/gi)
        //         let textWithMark = textList.map((rec,i)=>{
        //           let regex = new RegExp(`(${searchIndex})`,"ig")
        //           if(textList.length-1 != i){
        //             return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`) + tagList[i] 
        //           }
        //           else{
        //             return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`)
        //           }
        //         })
        //         content = textWithMark.join('')
        //         editor.innerHTML = content
        //       }
        //     }
        //     editor.innerHTML = content
        //   }
        //   else{
        //     if (content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"").includes(searchText)) {
        //       let textList = content.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"@htmlTag").split(/@htmlTag/)
        //       let tagList = content.match(/(<([^>]+)>)|&nbsp;|&nbsp/gi)
        //       let textWithMark = textList.map((rec,i)=>{
        //         let regex = new RegExp(`(${searchText})`,"ig")
        //         if(textList.length-1 != i){
        //           return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`) + tagList[i] 
        //         }
        //         else{
        //           return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`)
        //         }
        //       })
        //       content = textWithMark.join('')
        //       editor.innerHTML = content
        //     }
        //   }
        // }
        // //NOTE: highlight searched text 2024-05-14 end
        let linksLen = links.length - 1;
        // console.log("index 2", links[2]);
        // console.log("length", linksLen);

        for (let i = 0; i <= linksLen; i++) {
          let linkText = [];
          if (links[i].hasAttribute("href")) {
            // tanskie2
            linkText = links[i].getAttribute("href");
          }

          let linkSplit = "";
          let linkSplit1 = ""; // 2022-03-08

          let linkSplit2 = ""; // 2022-04-13
          let FileName = ""; // 2022-04-13

          if (linkText.length) {
            linkSplit = linkText.toString().split("/")[0];
            linkSplit1 = linkText.toString().split(":")[1]; // 2022-03-08

            // ( 2022-04-13)
            linkSplit2 = linkText.toString().split(".")[
              linkText.split(".").length - 1
            ];
            console.log("line1197 Extension", linkSplit2);

            FileName = linkText.split("/")[linkText.split("/").length - 1];
            console.log("line1201 Filename", FileName);
          }

          let newLink = "";
          if (linkText.includes("shiyo_document")) {
            let id = linkText.toString().split("/")[1];
            newLink = linkText.replace(/shiyo_document/g, "shiyoDocument");
            // console.log('line 786', newLink)
            let newProduct = "";

            let productObj = this.productNameArr.filter((rec) => {
              return rec.id == id;
            });
            // console.log(i, productObj[0].productName)
            if (productObj.length && productObj[0].productName !== undefined) {
              newProduct = productObj[0].productName;
            }

            links[i].setAttribute(
              "href",
              `${this.linkURL}/${newLink}?productName=${newProduct}`
            );

            links[i].setAttribute("target", "_blank"); // 2022-03-04
          }

          if (linkSplit == "document") {
            // console.log(i, 'value')
            links[i].setAttribute("href", `${this.linkURL}/${linkText}`);

            links[i].setAttribute("target", "_blank"); // 2022-03-04
          }

          // 2022-03-08  for Shiyo Manual PDF Only
          if (linkSplit1 == "//hrdapps48") {
            links[i].setAttribute("href", `${linkText}`);
            links[i].setAttribute("target", "_blank"); // 2022-03-04
          }

          // EXCEL DOWNLOAD LINK ( 2022-04-13 )
          // if(  linkSplit2 == 'xlsx' || linkSplit2 == 'xls' || linkSplit2 == 'docx' ) {

          //     let s3Url = 'https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files'
          //     console.log('line1301', this.shiyoData.shiyoNumber)
          //     let reference = `${s3Url}/${this.shiyoData.shiyoNumber}-${FileName}`
          //     setTimeout(() => {
          //         // Remove File Name in Directory
          //         let linkDeleteEnd = reference.split('/')
          //         linkDeleteEnd.pop()
          //         let linkDeleted = linkDeleteEnd.join('/')
          //         let dir = linkDeleted

          //         // Remove Duplicate Shiyo Number
          //         let linkSplit = reference.split('/')[reference.split('/').length -1 ];
          //         let titleName = linkSplit.split('-')
          //         titleName.shift();
          //         let fileName = titleName.join('-')

          //         links[i].setAttribute('href', `${dir}/${fileName}`);
          //         links[i].setAttribute('target', '_blank')
          //     }, 1200);
          // }

          continue;
        }
      }, 500);
      setTimeout(() => {
        this.highlightSearchedText()
      }, 1000);
    },
    LoadingImage(url) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(url).then(async (res) => {
        let content = [];
        content = res.data.htmlContent
        for (let i = 0; i < content.length; i++) {
          let indices = [];
          let imgName = [];

          let findStr = new RegExp(
            "http://hrdapps48:5010/uploads/image/froala/test/",
            //NOTE: for change imagelink 2024-03-26
            // "http://10.168.64.31:5010/uploads/image/froala/test/|http://hrdapps48:5010/uploads/image/froala/test/|https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/|https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/",
            "g"
          );
          let result = "";
          ////Getting the indeces of each http://hrdapps48:5010/uploads/image/froala/test/ in htmlContent and place it in array////
          while ((result = findStr.exec(content[i]))) {
            indices.push(result.index);
            //NOTE: for change imagelink 2024-03-26
            // indices.push([result.index,result[0]]);
          }
          //NOTE: for change imagelink 2024-03-26
          // let findExt = new RegExp(".png|.pjpeg|.pjp|.jpg|.jpeg|.jfif|.bmp|.ico|.cur|.tif|.tiff|.webp|.svg|.gif|.avif|.apng", "ig")
          if (indices.length > 0) {
            ////GETTING THE IMAGE NAME FROM THE FROALA LINK////
            imgName = indices.map((index) => {
              return content[i].substring(index + 48, index + 48 + 44);
            });
            //NOTE: for change imagelink 2024-03-26
            // imgName = indices.map((item) => {
            //     let end = findExt.exec(content[i],item[0]).index + 4
            //     return content[i].substring(item[0] + item[1].length, end);
            // });
          }

          for (let j = 0; j < imgName.length; j++) {
            let imageName = imgName[j].replace('(','[(]').replace(')','[)]')
            let deleteSrc = new RegExp(
              `src="http://hrdapps48:5010/uploads/image/froala/test/${imageName}"`
              //NOTE: for change imagelink 2024-03-26
              // `src="http://10.168.64.31:5010/uploads/image/froala/test/${imageName}"|src="http://hrdapps48:5010/uploads/image/froala/test/${imageName}"|src="https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/${imageName}"|src="https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/${imageName}"`
            );
            content[i] = content[i].replace(
              deleteSrc,
              `<img src='../loading.gif' height="auto" width="auto"`
            );
          }
          res.data.htmlContent[i] = content[i];
        }
        this.shiyoData2 = res.data;
        let findXLS = new RegExp(".xls", "g");
        if (this.shiyoData2.htmlContent.toString().match(findXLS) != null) {
          this.shiyoData2 = await res.data;
          await this.replaceLink();
        } else {
          this.shiyoData = res.data;
        }
        this.loading = false;

        this.ReplaceImageFromS3(url);
      });
    },
    checkFavorite() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      // let toCheck = {
      //     id: this.$route.params.id,
      //     userId: this.userInfo.id
      // }
      // let url = `${this.api}checkFavorite`;
      // console.log('line1444', toCheck )
      // axios.post(url, toCheck).then((res) => {
      //     this.isFavorite = res.data;

      //     console.log('line1447', this.isFavorite )
      // });
      let toCheck = {
        id: this.$route.params.id,
        userId: this.userInfo.id,
        shiyoNumber: this.shiyoData.shiyoNumber,
        productName: this.shiyoData.productName,
      };

      // console.log("line1444", toCheck);

      let url = `${this.api}checkShiyoFavorite`;
      axios.post(url, toCheck).then((res) => {
        // console.log("line1461", res);
        this.isFavorite = res.data;
        // console.log("line1447", this.isFavorite);
      });
    },
    FileSrc(filename) {
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });
      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: config,
      });
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
      });
      var options = {
        Bucket: "rulebook.files",
        Key: `Shiyo/${filename}`,
      };
      s3.getObject(options, function(err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else {
          var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });
          FileSaver.saveAs(blob, `${filename}`);
        } // successful response
      });
    },
  },
  async mounted() {
    window.FileSrc = this.FileSrc.bind(this);

    let url = "";
    if (this.$route.query.search && !this.$route.query.multi) {
      // console.log("line1427-3");
      this.loading = true;
      url = `${this.api}shiyo/searched_shiyo/${this.$route.params.id}?search=${this.$route.query.search}`;
    } else if (this.$route.query.search && this.$route.query.multi) {
      // console.log("line1431-2");

      this.loading = true;
      url = `${this.api}shiyo/search_multi/${this.$route.params.id}?search=${this.$route.query.search}`;
    } else {
      // console.log("line1436-1");
      this.loading = true;
      url = `${this.api}shiyo/get_shiyo/${this.$route.params.id}?productName=${this.$route.query.productName}`;
    }
    await this.LoadingImage(url);

    // alert(url)
  },
};
</script>

<style scoped>
#hover:hover {
  background-color: #daf5b3;
}

.viewed {
  background-color: #daf5b3;
}
.theme--light.v-card > .v-card__text {
  font-size: 18px;
}
#responsive {
  max-height: 80vh;
}
#responsive768 {
  max-height: 88vh;
}
#responsive1024 {
  max-height: 92vh;
}
#responsive1080 {
  max-height: 93vh;
}
#responsive1200 {
  max-height: 100vh;
}
a:hover {
  background-color: #e8e8e8;
}
.disabled {
  pointer-events: none;
  text-transform: none;
}

/* .FontStyle{
    font-family: "MS PGothic", Osaka, Arial, sans-serif !important;
  font-size: 18px !important;
} */
@media only print {
  body {
    position: static;
  }

  div.v-toolbar * {
    display: none !important;
  }
  div.v-toolbar__content * {
    display: none !important;
  }
  nav.v-toolbar.elevation-0.theme--light {
    display: none !important;
  }

  div.ant-tabs-nav-scroll * {
    display: none !important;
  }
  div.v-card.v-card--flat.v-sheet.theme--light {
    margin: 0mm;
    padding: 0mm;
    overflow-y: visible !important;
    position: relative;
  }

  .tox-notification {
    display: none !important;
  }

  .fr-view {
    font-family: "MS PGothic", Osaka, Arial, sans-serif;
    font-size: 18px;
  }

  .fr-view table.noborder tbody td {
    border: none;
  }

  .fr-view table.blackborder tbody td {
    border-color: black;
  }
  .fr-view td.tableCellStyles1 {
    color: red;
  }

  .fr-view span.rotateVerticalLeftRight {
    writing-mode: vertical-lr;
  }
  .fr-view .class1 tbody tr:nth-child(2n) {
    background: #d3d6db;
  }
  .fr-view .class2 thead tr th,
  .class2 tbody tr td {
    border-style: dashed;
  }
}
</style>
